<app-casino-header *ngIf="showMobileNavFlag"></app-casino-header>
<div class="main-content"> 
    <div class="container-with-sidebar">
        <app-navigation-bar *ngIf="showSidebarMenuCMS && showSidebarMenuFlag" class="container-sidebar"></app-navigation-bar>
        <section class="main-layout container-body">
            <main class="container">
                <router-outlet></router-outlet>
            </main>
        </section>
    </div> 
    
</div>

<app-navigation-bar-mobile *ngIf="showMobileNavFlag && showMobileNavFooterCMS"></app-navigation-bar-mobile>
