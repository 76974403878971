export const MobileNavigationDisplayStatePerPage = {
    GAMES: true,
    MAIN: true,
    REDEEM_COINS: false,
    PROMOTIONS: true,
    HOW_IT_WORKS: true,
    LEVELS: true,
    LEADERBOARD: true,
    HELP: true,
    LOYALITY_PROGRAM: true,
    COIN_STORE: false,
    SEARCH: false,
    PLAY: false,
    SIGN_UP: false,
    SIGN_IN: false,
    MENU: true,
}