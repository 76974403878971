import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {PopupItem} from "../interfaces";
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";

@Injectable({providedIn: 'root'})
export class PopupsProvider {

    constructor(
        private readonly http: HttpClient
    ) {
    }

    public getByTrigger(code: string): Observable<PopupItem[]> {
        const url = `${environment.ENDPOINTS.CMS.URL}/popups/${code}`;

        return this.http.get<PopupItem[]>(url);
    }

    public getPopup(code: string): Observable<PopupItem> {
        const url = `${environment.ENDPOINTS.CMS.URL}/popups/${code}`;

        return this.http.get<PopupItem>(url);
    }
}
