import {Component, OnDestroy, OnInit} from '@angular/core';
import {
    AuthService,
    NavigationBarService,
    UserInfoQuery,
    UserInfoService
} from "../../../core/store";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import { NavigationService } from 'src/app/core';
import { ModalService } from '../../services';

@Component({
    selector: 'app-casino-header',
    templateUrl: './casino-header.component.html',
    styleUrls: ['./casino-header.component.scss']
})
export class CasinoHeaderComponent implements OnInit, OnDestroy {
    private destroy$ = new Subject<boolean>();

    public balance!: number;
    public currency!: string | undefined;
    public isAuthenticated = false;
    public lcTotal = 0;
    public scTotal = 0;

    constructor(
        private readonly navigationBarService: NavigationBarService,
        private readonly authService: AuthService,
        private readonly userInfoService: UserInfoService,
        private readonly userInfoQuery: UserInfoQuery,
        private readonly navigationService: NavigationService,
        private readonly modalService: ModalService,
    ) {
        this.userInfoQuery.balances$.pipe(
        ).subscribe((balances) => {
            if(balances.length > 0) {
                ({ scTotal: this.scTotal, lcTotal: this.lcTotal } = this.userInfoService.countBalance(balances));
            }
        });
    }

    public showModalMenu(): void {
        this.navigationBarService.openModal()
    }

    ngOnInit(): void {
        this.authService.isAuthenticated().pipe(
            takeUntil(this.destroy$),
        ).subscribe((isAuthenticated) => {
            this.isAuthenticated = isAuthenticated;
        })
    }

    ngOnDestroy(): void {
        this.destroy$?.next(true);
        this.destroy$?.complete();
    }

    public navigate(link: string): void {
        this.modalService.closeAll();
        this.navigationService.navigateTo(link);
    }
}
