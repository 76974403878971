import {Injectable, OnDestroy} from "@angular/core";
import {UserBalanceDetails, UserInfo} from "src/app/core/interfaces";
import {AuthProvider} from "src/app/core/providers";
import {UserInfoQuery, UserInfoStore} from "src/app/core/store/user";
import {combineLatest, Observable, Subject} from "rxjs";
import {filter, first, map, take, tap, withLatestFrom} from "rxjs/operators";
import {Currency} from "../../../shared";
import {PlayerStatus} from "../../enums";
import {AuthService} from "../auth";

@Injectable({providedIn: 'root'})
export class UserInfoService implements OnDestroy {
    private destroy$ = new Subject<boolean>();

    constructor(
        private readonly userInfoStore: UserInfoStore,
        private readonly userInfoQuery: UserInfoQuery,
        private readonly authProvider: AuthProvider,
        private readonly authService: AuthService,
    ) {
    }


    ngOnDestroy(): void {
        this.destroy$?.next(true);
        this.destroy$?.complete();
    }

    public getUser(): Observable<UserInfo | null> {
        return this.userInfoQuery.user$.pipe(
            withLatestFrom(
                this.userInfoQuery.userLoaded$,
                this.userInfoQuery.userIsLoading$,
                this.authService.isAuthenticated()
            ),
            tap(([_, loaded, loading, isAuthenticated]) => {
                if (isAuthenticated && !loaded && !loading) {
                    this.loadUser();
                }
            }),
            map(([user, loaded]) => {
                return loaded ? user : null;
            })
        )
    }

    public loadUser(): void {
        this.userInfoStore.setUserLoading(true);

        this.authProvider.getCurrentUser().subscribe((user) => {
            this.userInfoStore.setUser(user);
        })
    }

    public getBalances(): Observable<UserBalanceDetails[]> {
        return combineLatest([
            this.getUser(),
            this.userInfoQuery.balances$
        ]).pipe(
            withLatestFrom(
                this.userInfoQuery.balancesLoaded$,
                this.userInfoQuery.balancesIsLoading$
            ),
            filter(([[user]]) => user !== null),
            tap(([[user, _], loaded, loading]) => {
                if (!loaded && !loading) {
                    this.loadBalances(user!.id);
                }
            }),
            map(([[_, balance]]) => {
                return balance;
            })
        )
    }


    public getBalanceTotals(): { scTotal: number, lcTotal: number } {
        let scTotal = 0;
        let lcTotal = 0;

        this.userInfoQuery.balances$.pipe(
            take(1)
        ).subscribe((balances) => {
            if(balances.length > 0) {
              ({ scTotal, lcTotal } = this.countBalance(balances));
            }
        });

        return { scTotal, lcTotal };
    }

    public loadBalances(userId: number): void {
        this.userInfoStore.setBalanceLoading(true);

        this.authProvider.getBalance(userId).subscribe((balances) => {
            this.userInfoStore.setBalances(balances);
        })
    }

    public setBalances(balances: UserBalanceDetails[]): void {
        this.userInfoStore.setBalances(balances);
    }

    public reloadBalances(): void {
        this.getUser().pipe(
            first(),
            tap((user) => {
                this.loadBalances(user!.id)
            })
        ).subscribe()
    }

    public switchCurrency(): void {
        this.userInfoQuery.selectedCurrency$.pipe(
            take(1),
        ).subscribe((selectedCurrency) => {
            const currency = selectedCurrency === Currency.SWPC ? Currency.LUKC : Currency.SWPC
            this.userInfoStore.setCurrency(currency);
        })
    }

    public getCurrentBalance(): Observable<UserBalanceDetails | undefined> {
        return combineLatest([
            this.getBalances(),
            this.userInfoQuery.selectedCurrency$
        ]).pipe(
            map(([balances, selectedCurrency]) => {
                return balances.find(b => b.currency == selectedCurrency);
            })
        )
    }

    public updateStatus(status: PlayerStatus): void {
        this.clear();
        this.getUser().pipe(take(1))
            .subscribe(user => {
                if (!user) return

                this.userInfoStore.setUser({
                    ...user,
                    status: status
                });
            });
    }

    public setHasCoinArea(hasCoinArea: boolean): void {
        this.userInfoStore.setHasCoinArea(hasCoinArea);
    }

    public clear(): void {
        this.userInfoStore.clear();
    }

    public countBalance(balances: UserBalanceDetails[]): { scTotal: number, lcTotal: number } {
        const balanceSC = balances.find((b: any) => b.currency === Currency.SWPC)
        const scTotal = balanceSC ? balanceSC.balance + balanceSC.lockedBalance : 0;
        const balanceLC = balances.find((b: any) => b.currency === Currency.LUKC);
        const lcTotal = balanceLC ? balanceLC.balance + balanceLC.lockedBalance : 0;
        return { scTotal, lcTotal };
    }
}