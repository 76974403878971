import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
    providedIn: 'root',
})
export class LayoutService {
    private showFooterSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    public showFooterFlag$ = this.showFooterSubject.asObservable();

    private showSidebarSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    public showSidebarFlag$ = this.showSidebarSubject.asObservable();

    private showMenuSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public showMenuFlag$ = this.showMenuSubject.asObservable();

    public setShowFooterFlag(value: boolean) {
        this.showFooterSubject.next(value);
    }

    public getShowFooterFlag(): Observable<boolean> {
        return this.showFooterFlag$;
    }

    public setShowSidebarFlag(value: boolean) {
        this.showSidebarSubject.next(value);
    }

    public getShowSidebarFlag(): Observable<boolean> {
        return this.showSidebarFlag$;
    }

    public setShowMenuFlag(value: boolean) {
        this.showMenuSubject.next(value);
    }

    public getShowMenuFlag(): Observable<boolean> {
        return this.showMenuFlag$;
    }
}