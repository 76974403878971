export enum BannerAlignment {
    Left = "Left",
    Center = "Center",
    Right = "Right"
}

export enum BannerActionStyle {
    Default = "Default",
    Secondary = "Secondary",
    Outlined = "Outlined",
    Blue = "Blue",
    Orange = "Orange",
    Green = "Green",
    Red = "Red",
    White = "White",
}

export enum BannerActionType {
    Signup = "Signup",
    Purchase = "Purchase",
    Link = "Link",
    Play = "Play",
    External = "External"
}

export enum BannerActionSize {
    Default = "Default",
    Small = "Small",
    Large = "Large",
    ExtraLarge = "ExtraLarge",
}