<ul class="navigation-wrapper">
    <li class="nav-link" *ngFor="let link of allLinks" (click)="navigate(link)" [class.accent]="link.Title === 'Sign Up'">
        <ng-container *ngIf="link.Title !== 'Get Coins'">
            <span class="nav-link__icon">
                <img [attr.aria-label]="link.Title" [src]="link.Icon.url" [alt]="link.Title">
            </span>
            <label class="nav-link__label">{{link.Title}}</label>
        </ng-container>
        <ng-container *ngIf="link.Title === 'Get Coins'">
            <a
                class="coin-store-btn"
                test_id="getCoinsLink"
            >
                <span>Get Coins</span>
            </a>
        </ng-container>
    </li>
</ul>
