import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Authenticated, CanSignIn } from './core';

const routes: Routes = [
  {
    path: 'games',
    loadChildren: () =>
      import('./modules/games/games.module').then(m => m.GamesModule),
  },
  {
    path: 'coinstore',
    canActivate: [Authenticated],
    data: { returnToAfterSignIn: true },
    loadChildren: () =>
      import('./modules/games/games.module').then(m => m.GamesModule),
  },
  {
    path: 'account',
    canActivate: [Authenticated],
    loadChildren: () =>
      import('./modules/account/account.module').then(m => m.AccountModule),
  },
  {
    path: 'auth',
    canActivate: [CanSignIn],
    loadChildren: () =>
      import('./modules/auth/auth.module').then(m => m.AuthModule),
  },
  {
    path: 'pages',
    loadChildren: () =>
      import('./modules/pages/pages.module').then(m => m.PagesModule),
  },
  {
    path: 'casino',
    children: [
      {
        path: '**',
        redirectTo: '/',
      },
    ],
  },
  {
    path: '',
    loadChildren: () =>
      import('./modules/static/static.module').then(m => m.StaticModule),
  },
  {
    path: '**',
    redirectTo: '/404',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}