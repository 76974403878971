import { Injectable } from '@angular/core';
import { PopupBasicInfo } from '../interfaces';
import { BehaviorSubject, Observable } from 'rxjs';


@Injectable({
    providedIn: 'root',
})
export class PopupService {
    private isReady = false;
    private isTerminated = false;
    private popupState = new Map<string, string>();
    private isReadyPopupAction: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    private openedQueryPopupId: number | null = null;

    constructor() {
        const storedPopupState = localStorage.getItem('popupState');
        if (storedPopupState) {
            const parsedPopupState = JSON.parse(storedPopupState);
            this.popupState = new Map<string, string>(parsedPopupState);
        }
    }
    
    updatePopupHistory(popupId: number): void {
      this.popupState.set(popupId.toString(), new Date().toISOString());
      localStorage.setItem('popupState', JSON.stringify(Array.from(this.popupState.entries())));
    }

    public setOpenedQueryPopupId(popupId: number | null): void {
        this.openedQueryPopupId = popupId;
    }

    public setTerminated(value: boolean): void {
        this.isTerminated = value;
    }

    public getTerminated(): boolean {
        return this.isTerminated;
    }
    
    public setIsReady(value: boolean) {
        this.isReady = value;
        this.isReadyPopupAction.next(this.isReady);
    }

    public isReady$(): Observable<boolean> {
        return this.isReadyPopupAction.asObservable();
    }

    filterPopups(
        popups: PopupBasicInfo[],
      ): PopupBasicInfo[] {
        const now = new Date();

        return popups
          .filter((popup: PopupBasicInfo) => {

            const popupHistory = this.popupState.get(popup.id.toString());
              // Filter out popup that is already opened via query param
            if (popup.id === this.openedQueryPopupId) return false;
            if (popupHistory && popup.CoolDown) {
              const lastShownDate = new Date(popupHistory);
              // TODO: Change minutes to hours
              const cooldown = new Date(lastShownDate.getTime() + popup?.CoolDown * 60 * 1000);
              // Filter out popups that are still in cooldown period
              if (now < cooldown) return false;
            }
    
            return true;
          })
      }
    
      preparePopupQueue(filteredPopups: PopupBasicInfo[], maxPopups?: number): PopupBasicInfo[] {
        return filteredPopups.slice(0, maxPopups); 
      }

}