import { Component, Inject, OnInit } from '@angular/core';
import {
  BannerActionType,
  ContentPopupImageBlock,
  CountDownBlock,
  CTABlock,
  DividerBlock,
  NavigationService,
  PopupBlocks,
  PopupItem,
  RichTextBlock,
} from '../../../core';
import { AuthService } from 'src/app/core/store';
import { ModalService } from '../../services';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as dayjs from 'dayjs';
import * as duration from 'dayjs/plugin/duration';
import { SignInModalComponent } from '../sign-in-modal/sign-in-modal.component';
import { PopupService, CoinPackageService } from '../../../core';
import { castTo } from '../../utils';

dayjs.extend(duration);

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss'],
})
export class PopupComponent implements OnInit {
  public popup: PopupItem | null = null;
  public timer: any;
  public countdownDisplay = '';
  public isTermsConditionsExpanded = false;
  public PopupBlockTypes = PopupBlocks;
  public $richText = castTo<RichTextBlock>();
  public $cta = castTo<CTABlock>();
  public $image = castTo<ContentPopupImageBlock>();
  public $countdown = castTo<CountDownBlock>();
  public $divider = castTo<DividerBlock>();
  public countDown: CountDownBlock | null = null;
  public isExpendedText = false;
  public showMoreFlag = false;
  public POPUP_DEFAULT_MAX_WIDTH = 600;

  constructor(
    private readonly modalService: ModalService,
    private readonly authService: AuthService,
    private coinPackageService: CoinPackageService,
    private readonly navigationService: NavigationService,
    private popupService: PopupService,
    @Inject(MAT_DIALOG_DATA) data: any
  ) {
    this.popup = data;
  }

  ngOnInit(): void {
    this.findCountDownTimer();
    this.findCollapsibleText();
  }

  public getBackgroundOpacity(transparency: number): number {
    return (100 - transparency) / 100;
  }

  public countdownTimer(countdown: number): string {
    const totalSeconds = countdown * 60;
    const duration = dayjs.duration(totalSeconds, 'seconds');

    const hours = duration.hours().toString().padStart(2, '0');
    const minutes = duration.minutes().toString().padStart(2, '0');
    const seconds = duration.seconds().toString().padStart(2, '0');

    if (duration.asHours() >= 1) {
      return `${hours}:${minutes}:${seconds}`;
    } else {
      return `${minutes}:${seconds}`;
    }
  }

  public startTimer(countdown: number): void {
    let totalSeconds = countdown * 60;
    this.countdownDisplay = this.countdownTimer(totalSeconds / 60);
    this.timer = setInterval(() => {
      if (totalSeconds > 0) {
        totalSeconds--;
        this.countdownDisplay = this.countdownTimer(totalSeconds / 60);
      } else {
        this.stopTimer();
        this.close();
      }
    }, 1000);
  }

  public stopTimer(): void {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
  }

  public toggleTermsConditions(): void {
    this.isTermsConditionsExpanded = !this.isTermsConditionsExpanded;
  }

  public toggleIsExpendedText(): void {
    this.isExpendedText = !this.isExpendedText;
  }

  onActionClick(action: BannerActionType | '', item: any) {
    if (!action) return;
    this.popupService.setTerminated(true);
    this.close();
    switch (action) {
      case BannerActionType.Signup:
        this.navigationService.navigateToWithPreservedQueryParams(
          './auth/sign-up'
        );
        break;
      case BannerActionType.Purchase:
        this.authService
          .isAuthenticated()
          .subscribe((isAuthenticated: boolean) => {
            if (isAuthenticated) {
              this.coinPackageService.openCoinStoreModal();
            } else {
              localStorage.setItem('urlAfterSignIn', '/coinstore');
              this.modalService.open(SignInModalComponent);
            }
          });
        break;
      case BannerActionType.Link:
      case BannerActionType.External: {
        const url = item.Url || item.ExternalLink;
        this.navigationService.navigateToExternal(url);
        break;
      }
      case BannerActionType.Play:
        if (item.Game) {
          this.navigationService.navigateTo(
            `/games/${item.Game.Provider.Slug}/${item.Game.Slug}/play`
          );
        } else {
          this.navigationService.navigateTo('404');
        }
        break;
    }
  }

  close() {
    this.modalService.closeAll();
  }

  private findCountDownTimer() {
    this.countDown =
      (this.popup?.Content.find(block => {
        return block.__component === PopupBlocks.Countdown;
      }) as CountDownBlock) || null;

    if (this.countDown && this.countDown.Timer) {
      this.startTimer(this.countDown.Timer);
    }
  }

  private findCollapsibleText(): void {
    const richTextBlock =
      (this.popup?.Content.find(block => {
        return block.__component === PopupBlocks.RichText;
      }) as RichTextBlock) || null;

    if (richTextBlock) {
      this.showMoreFlag = richTextBlock.Collapsible;
    }
  }
}