export enum PageBlocks {
    HTML = 'page-contents.html',
    GameStrip = 'page-contents.game-strip',
    GameTabs = 'page-contents.game-tabs',
    Banner = 'page-contents.banner',
    Wysiwyg = 'page-contents.wysiwyg',
    BenefitsTable = 'page-contents.benefits-table',
    Cards = 'page-contents.cards',
    Levels = 'page-contents.loyalty-levels',
    Leaderboard = 'page-contents.leaderboard',
    Media = 'page-contents.media',
}

export enum BannerBlocks {
    Simple = 'components.banner-content',
    Advanced = 'components.advance-banner-content'
}

export enum PopupBlocks {
    RichText = 'popup-contents.rich-text',
    Images = 'popup-contents.img',
    CTA = 'popup-contents.cta',
    Countdown = 'popup-contents.countdown',
    Divider = 'popup-contents.divider',
}