import {
  AfterContentInit,
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  Renderer2,
  isDevMode,
} from '@angular/core';
import { IngeniousService, PopupsProvider } from './core';
import {
  CheckPlatformService,
  LayoutService,
  ModalService,
} from './shared/services';
import { Location } from '@angular/common';
import {
  CmsSettingsService,
  HubService,
  PaymentsService,
  ResponsibleGamblingService,
  SeoService,
} from './core/store';
import { filter, take, takeUntil } from 'rxjs/operators';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { combineLatest, Subject } from 'rxjs';
import { PopupComponent } from './shared/modals';
import { environment } from '../environments/environment';
import { LocalStorageEnums } from './shared/enums';
import { AnalyticsService, PopupService } from './core';
import { MobileNavigationDisplayStatePerPage } from './shared/constants/mobile-nav-display-state';
import { SidebarMenuDisplayStatePerPage } from './shared/constants/sidebar-menu-display-state';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent
  implements OnInit, OnDestroy, AfterContentInit, AfterViewInit
{
  private destroy$ = new Subject<boolean>();

  public showMobileNavFlag = false;
  public showSidebarMenuFlag = true;
  public showMobileNavFooterCMS = true;
  public showSidebarMenuCMS = true;

  constructor(
    private readonly _router: Router,
    private readonly _activatedRoute: ActivatedRoute,
    private readonly analyticsService: AnalyticsService,
    private readonly seoService: SeoService,
    private readonly paymentsService: PaymentsService,
    private readonly renderer: Renderer2,
    private readonly hubService: HubService,
    private readonly checkPlatformService: CheckPlatformService,
    private readonly cmsSettingsService: CmsSettingsService,
    private readonly modalService: ModalService,
    private readonly ing: IngeniousService,
    private readonly responsibleGamblingService: ResponsibleGamblingService,
    private readonly popupsProvider: PopupsProvider,
    private popupService: PopupService,
    private layoutService: LayoutService,
    private location: Location
  ) {
    const welcomeFlag = localStorage.getItem(LocalStorageEnums.WelcomeFlag);
    if (welcomeFlag === null) {
      localStorage.setItem(LocalStorageEnums.WelcomeFlag, 'true');
    }
  }

  ngOnInit(): void {
    this._router.events
      .pipe(
        filter(
          (event): event is NavigationEnd => event instanceof NavigationEnd
        ),
        takeUntil(this.destroy$)
      )
      .subscribe((event: NavigationEnd) => {
        if (isDevMode()) {
          console.log(`Route change to ${event.urlAfterRedirects}`);
        }

        this.showMobileNavFlag = this.findFlag(
          event.urlAfterRedirects,
          MobileNavigationDisplayStatePerPage,
          true
        );
        this.showSidebarMenuFlag = this.findFlag(
          event.urlAfterRedirects,
          SidebarMenuDisplayStatePerPage,
          true
        );
        this.analyticsService.trackPageView();
      });

    this.listenDoesPopupExist();

    combineLatest([
      this.layoutService.showFooterFlag$,
      this.layoutService.showSidebarFlag$,
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([showFooterFlag, showSidebarFlag]) => {
        this.showMobileNavFooterCMS = showFooterFlag;
        this.showSidebarMenuCMS = showSidebarFlag;
      });
  }

  ngOnDestroy(): void {
    this.destroy$?.next(true);
    this.destroy$?.complete();
  }

  ngAfterContentInit(): void {
    if (this.checkPlatformService.checkIfIsPlatformBrowser()) {
      this._router.events
        .pipe(
          takeUntil(this.destroy$),
          filter(event => event instanceof NavigationEnd)
        )
        .subscribe(() => {
          this.ing.ingeniousHandler.next('ingeniousHandler');

          if (environment.ENVIRONMENT === 'production') {
            this.seoService.updateCanonicalUrl();
          }
        });

      this.hubService.connect();
      this.responsibleGamblingService.initActivityCheck();
    }

    this.cmsSettingsService
      .getSettings()
      .pipe(
        filter(s => s !== null),
        take(1)
      )
      .subscribe(settings => {
        this.seoService.setMetaTags(settings!.seo);
      });
  }

  ngAfterViewInit() {
    if (this.checkPlatformService.checkIfIsPlatformBrowser()) {
      setTimeout(() => {
        const loader = this.renderer.selectRootElement('#loader');
        this.renderer.removeClass(loader, 'active');
      }, 2000);

      this.paymentsService.loadFlykkScript(this.renderer);
      this.paymentsService.loadPayNearMeScript(this.renderer);
    }
  }

  private findFlag(url: string, type: any, defaultShow: boolean): boolean {
    let lastSegment = url.split('/').filter(Boolean).pop();
    lastSegment = lastSegment?.toLocaleUpperCase().replace(/-/g, '_');
    return type[lastSegment as keyof typeof type] ?? defaultShow;
  }

  private listenDoesPopupExist() {
    this._activatedRoute.queryParams
      .pipe(takeUntil(this.destroy$))
      .subscribe(params => {
        if (!params['pop_up']) return;

        this.popupService.setIsReady(false);
        const popupCode = params['pop_up'];
        this.popupsProvider
          .getPopup(popupCode)
          .pipe(take(1))
          .subscribe({
            next: popup => {
              if (popup === null) {
                this.popupService.setIsReady(true);
                this.removeQueryParameterWithoutReloadingThePage();
                return;
              }

              this.popupService.updatePopupHistory(popup.id);
              this.popupService.setOpenedQueryPopupId(popup.id);

              // Open popup delayed to see animation
              setTimeout(() => {
                const dialogRef = this.modalService.open(PopupComponent, {
                  data: popup,
                  maxWidth: popup.MaxWidth ? popup.MaxWidth : 600,
                  enterAnimationDuration: 500,
                });
                dialogRef.afterClosed().subscribe(() => {
                  this.popupService.setIsReady(true);
                  // Set opened popup id to null once it's closed to be able to open it again on other pages
                  this.popupService.setOpenedQueryPopupId(null);
                  this.removeQueryParameterWithoutReloadingThePage();
                });
              }, 2000);
            },
          });
      });
  }

  private removeQueryParameterWithoutReloadingThePage() {
    this.location.replaceState(this.location.path().split('?')[0]);
  }
}