<ng-container *ngIf="popup">
    <div class="modal" [ngStyle]="{'width.px' : popup.MaxWidth || POPUP_DEFAULT_MAX_WIDTH }" [ngClass]="{'no-border': popup.BackgroundTransparency === 100}">
        <button class="close-button" (click)="close()" aria-label="Close">
            <i class="u_times" aria-describedby="Close"></i>
        </button>

        <div class="popup-background" [ngStyle]="{'background-color': popup.BackgroundColor || '', 'opacity': getBackgroundOpacity(popup.BackgroundTransparency)}"></div>

        <div class="modal__content" *ngIf="popup.Type === 'Internal'; else externalContent">
            <div *ngIf="popup.Label" class="label-top-left" [ngStyle]="{'background-color': popup.LabelBackgroundColor}">
                <span>{{ popup.Label }}</span>
            </div>
            <div *ngFor="let content of popup.Content">
                <ng-container *ngIf="content.__component === PopupBlockTypes.Images">
                    <div *ngIf="$image(content)?.Image?.url" class="content-wrapper image-container">

                        <img [src]="$image(content).Image?.url" 
                            [alt]="$image(content).Alt" 
                            (click)="onActionClick($image(content).Action || '', $image(content).Image)" tabindex="0" 
                            [ngClass]="{'pointer': $image(content).Action}"
                        />
                    </div>
                </ng-container>
                <ng-container *ngIf="content.__component === PopupBlockTypes.CTA">
                    <div class="cta-container content-wrapper">
                        <button  class="btn btn-outlined btn-block btn-large" [ngClass]="$cta(content).Type" (click)="onActionClick($cta(content).Action, $cta(content))">
                            <img *ngIf="$cta(content)?.ImageIcon" [src]="$cta(content).ImageIcon?.url" [alt]="$cta(content).Alt">
                            <i *ngIf="!$cta(content)?.ImageIcon && $cta(content)?.Icon" [ngClass]="'bi bi-'+$cta(content)?.Icon"></i>
                            {{ $cta(content).Text }}
                            <i *ngIf="$cta(content)?.Type === 'Link'" class="u_angle-right-b"></i>
                        </button>
                    </div>
                </ng-container>
                <ng-container *ngIf="content.__component === PopupBlockTypes.RichText">
                    <div class="rich-text-container" [ngStyle]="{ '--fade-background': popup.BackgroundColor }">
                        <div [ngClass]="{'expanded': !showMoreFlag || isExpendedText}" class="html-data content-wrapper rich-text" [innerHTML]="$richText(content).Content ?? '' | safe: 'html'"></div>
                    </div>
                    <div *ngIf="showMoreFlag" (click)="toggleIsExpendedText()" class="show-more-text">{{ isExpendedText ? "Show Less" : "Show More"}}</div>
                    
                </ng-container>
                <ng-container *ngIf="content.__component === PopupBlockTypes.Countdown">
                    <div class="countdown content-wrapper" *ngIf="$countdown(content)?.Timer">
                        <div class="coundown-value">{{ countdownDisplay }}</div>
                    </div>
                </ng-container>
                <ng-container *ngIf="content.__component === PopupBlockTypes.Divider && $divider(content)?.Height">
                    <div class="content-wrapper" [ngStyle]="{'height': $divider(content).Height + 'px'}"></div>
                  </ng-container>
            </div>
        <div class="terms-conditions" *ngIf="popup.TermsConditions" >
            <div class="terms-conditions__container" [ngStyle]="{ '--fade-background': popup.BackgroundColor }">
                <div class="terms-conditions__text" [class.expanded]="isTermsConditionsExpanded">
                    {{ popup.TermsConditions }}
                </div>
            </div>
            <i class="arrow" aria-describedby="Toggle" (click)="toggleTermsConditions()" [ngClass]="{'u_angle-up': isTermsConditionsExpanded, 'u_angle-down': !isTermsConditionsExpanded}"></i>
        </div>
        </div>
        <ng-template #externalContent>
            <iframe height="550" width="100%" [src]="popup.ExternalPopupLink | safe: 'resourceUrl'" frameborder="0"></iframe>
        </ng-template>

    </div>
</ng-container>