import {Component, OnDestroy, OnInit} from '@angular/core';
import {AuthService, NavigationBarService, NavigationLinksService} from "../../../core/store";
import {Router} from "@angular/router";
import {CoinPackageService} from "../../../core/services/coin-package.service";
import {switchMap, takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";
import {LayoutService, ModalService} from "../../services";
import {SearchComponent} from "../../modals";
import {NavigationLink, NavigationService} from 'src/app/core';

@Component({
    selector: 'app-navigation-bar-mobile',
    templateUrl: './navigation-bar-mobile.component.html',
    styleUrls: ['./navigation-bar-mobile.component.scss']
})
export class NavigationBarMobileComponent implements OnInit, OnDestroy {
    public isAuthenticated = false;
    public isLoaded = false;
    public allLinks: NavigationLink[] = [];
    private destroy$ = new Subject<boolean>();
    private modalMenuOpened = false;

    constructor(
        private readonly _router: Router,
        private readonly _coinPackageService: CoinPackageService,
        private readonly authService: AuthService,
        private readonly modalService: ModalService,
        private readonly navigationLinksService: NavigationLinksService,
        private readonly navigationBarService: NavigationBarService,
        private readonly navigationService: NavigationService,
        private readonly layoutService: LayoutService,
    ) {
        this.layoutService.getShowMenuFlag().pipe(
            takeUntil(this.destroy$),
        )
        .subscribe((showMenu) => {
            this.modalMenuOpened = showMenu;
        });
    }

    ngOnInit(): void {
        this.authService.isAuthenticated().pipe(
            takeUntil(this.destroy$),
            switchMap((isAuthenticated) => {
                this.isAuthenticated = isAuthenticated;
                return isAuthenticated
                    ? this.navigationLinksService.getLinks('mobile-nav-authenticated')
                    : this.navigationLinksService.getLinks('mobile-nav-anonymous');
            })
        ).subscribe((links) => {
            this.allLinks = links;
            this.isLoaded = true;
        })
    }

    public navigate(link: NavigationLink): void {
        this.modalService.closeAll();

        switch (link.Title) {
            case 'Get Coins': {
                this.openCoinStore();
                break;
            }

            case 'Search': {
                this.modalService.open(SearchComponent)
                break;
            }

            case 'Menu': {
                this.showModalMenu();
                break;
            }

            default: {
                this._router.navigateByUrl(link.Url);
                break;
            }

        }

    }

    public showModalMenu(): void {
        
        if (this.modalMenuOpened) { 
            this.modalService.closeAll();
        } else {
            this.navigationBarService.openModal(false);
        }
        this.layoutService.setShowMenuFlag(!this.modalMenuOpened);
    }

    public openCoinStore() {
        if (this.isAuthenticated) {
            this._coinPackageService.openCoinStoreModal();
        } else {
            localStorage.setItem('urlAfterSignIn', '/coinstore');
            this.navigationService.navigateTo('/auth/sign-in');
        }
    }

    ngOnDestroy(): void {
        this.destroy$?.next(true);
        this.destroy$?.complete();
    }
}
