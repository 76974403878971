<nav class="header header-mobile">
    <ng-container>
        <div class="balance-wrapper"
            [ngStyle]="{'height': isAuthenticated ? '19px' : 'unset'}"
            test_id="balanceWrapper">
            <ng-container *ngIf="isAuthenticated">
                <span>Balance:</span><b>{{' SC:' + scTotal + '| LC:' + lcTotal }}</b>
            </ng-container>
        </div>
        <div class="header-mobile__content-wrapper">
            <div class="header-mobile__line-block">
                <div class="header-mobile__logo">
                    <a [routerLink]="'/'">
                        <img src="../../../../assets/images/LuckySweep-Logo.svg" alt="Caisno Logo" >
                    </a>
                </div>
            </div>
            <div class="balance" *ngIf="isAuthenticated">
                <app-toggle-coins [horizontalView]="true" [hideTitle]="true"></app-toggle-coins>
            </div>
            <div *ngIf="!isAuthenticated" class="header-mobile__actions">
                <button test_id="loginBtn" (click)="navigate('/auth/sign-in')" class="btn btn-outlined auth-btn color-white">
                    Log In
                </button>
                <button test_id="signUpBtn" (click)="navigate('/auth/sign-up')" class="btn btn-outlined auth-btn primary-color">
                    <span>Sign Up</span>
                </button>
            </div>
        </div>
        
    </ng-container>
</nav>