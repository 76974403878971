import { Directive, Input, HostBinding, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[appMobileNavDisplayLogic]',
})
export class MobileNavDisplayLogicDirective implements OnChanges {
  @Input() isModal = false;
  @Input() isAuthenticated = false;
  @Input() showMobileNavFlag = false;

  @HostBinding('class.display-modal-with-mobile-navigation-authorize') isAuthorizeClass = false;
  @HostBinding('class.display-modal-with-mobile-navigation-unauthorize') isUnauthorizeClass = false;

  ngOnChanges(changes: SimpleChanges): void {
    this.updateClasses();
  }

  private updateClasses(): void {
    this.isAuthorizeClass = this.showMobileNavFlag && this.isModal && this.isAuthenticated;
    this.isUnauthorizeClass = this.showMobileNavFlag && this.isModal && !this.isAuthenticated;
  }
}