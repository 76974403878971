import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {CasinoPage} from "../interfaces";
import {environment} from "../../../environments/environment";
import {HttpClient, HttpParams} from "@angular/common/http";

@Injectable({providedIn: 'root'})
export class PagesProvider {

    constructor(
        private readonly http: HttpClient
    ) {
    }

    public getPage(slug: string, userId?: number): Observable<CasinoPage> {
        let url = `${environment.ENDPOINTS.CMS.URL}/pages/${slug}`;
        let params = new HttpParams();

        if (userId) {
            params = params.set('playerId', userId.toString());
        }

        return this.http.get<CasinoPage>(url, {params});
    }
}