import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Location} from '@angular/common'
import {environment} from "../../../environments/environment";

@Injectable({providedIn: 'root'})
export class NavigationService {
    constructor(
        private _router: Router,
        private location: Location
    ) {
    }

    public navigateTo(url: string): void {
        this._router.navigate([url]);
    }

    public navigateToWithPreservedQueryParams(url: string): void {
        this._router.navigate([url], {queryParamsHandling: "preserve"});
    }

    public navigateAndRefreshTo(url: string): void {
        this._router.routeReuseStrategy.shouldReuseRoute = () => false;
        this._router.onSameUrlNavigation = 'reload';
        this._router.navigate([url]);
    }

    public navigateToWithParams(url: string, params?: any): void {
        this._router.navigate([url], {queryParams: params});
    }

    public navigateBack(): void {
        this.location.back();
    }

    public navigateToExternal(link: string): void {
        if(!link) return;
        if (link.includes(environment.CURRENT_URL)) {
            try {
                const url = new URL(link)

                link = url.pathname;
            } finally {
                this.navigateTo(link);
            }
        } else {

            location.href = this.getHttpsLinkPrefix(link);
        }
    }

    private getHttpsLinkPrefix(link: string): string {
        if (!link.startsWith('http://') && !link.startsWith('https://')) {
            return 'https://' + link;
        }
        return link;
    }

    public navigateToWithState(url: string, state: any): void {
        this._router.navigate([url], {state: state});
    }
}