import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  CoinPackageOffer,
  AnalyticsEvent,
  AnalyticsService,
  PaymentMethod,
  PaymentProvider,
  PaymentsProvider,
  PaymentType,
  CheckoutStartedEventData,
} from '../../../../../core';
import { EventBus } from '../../../../../core/infrastructure';
import { EVENT_TYPES } from '../../../../constants';
import {
  PaymentsService,
  AnalyticsService as AnalyticsStoreService,
} from '../../../../../core/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-payment-method',
  templateUrl: './payment-method.component.html',
  styleUrls: ['./payment-method.component.scss'],
})
export class PaymentMethodComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<boolean>();

  @Output()
  public onBackButtonPressed: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  @Output()
  public onPaymentComplete: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  public coin!: CoinPackageOffer;

  @Input()
  public coinGridPosition: number = 0;

  public paymentProviders: PaymentProvider[] = [];
  public paymentMethods: PaymentMethod[] = [];
  public paymentInProgress: boolean = false;

  constructor(
    private readonly eventBus: EventBus,
    private readonly paymentsService: PaymentsService,
    private readonly paymentsProvider: PaymentsProvider,
    private readonly analyticsService: AnalyticsService,
    private readonly analyticsStoreService: AnalyticsStoreService
  ) {}

  ngOnInit(): void {
    this.paymentsProvider
      .getActivePaymentMethods()
      .pipe(takeUntil(this.destroy$))
      .subscribe(methods => {
        this.paymentMethods = methods;

        if (this.coin.paymentMethods.length > 0) {
          this.paymentMethods = methods.filter(m =>
            this.coin.paymentMethods.includes(m.id)
          );
        }
      });

    this.paymentsService
      .getProviders()
      .pipe(takeUntil(this.destroy$))
      .subscribe(providers => {
        this.paymentProviders = providers;
      });
  }

  isGooglePay(method: PaymentMethod): boolean {
    const paymentProvider = this.paymentProviders.find(
      p => p.id === method.defaultProviderId
    );

    if (paymentProvider) return paymentProvider.type === PaymentType.GooglePay;

    return false;
  }

  back(): void {
    this.onBackButtonPressed.emit(true);
  }

  onComplete(status: any) {
    this.paymentInProgress = false;
    this.onPaymentComplete.emit(status);
  }

  onClose() {
    this.paymentInProgress = false;
  }

  startPayment(paymentMethod: PaymentMethod) {
    this.eventBus.Publish(
      EVENT_TYPES.PAYMENT_STARTED,
      paymentMethod.defaultProviderId
    );

    this.paymentInProgress = true;

    // map eventData from the coin package and payment method
    const eventData: CheckoutStartedEventData = {
      ...this.analyticsService.mapCoinPackageEventData(
        this.coin,
        this.coinGridPosition
      ),
      payment_method: paymentMethod.title,
    };

    this.analyticsService.trackEvent(
      AnalyticsEvent.CHECKOUT_STARTED,
      eventData
    );
    // set buying coin package to the store to be used in the hub.service.ts when the purchase is completed
    this.analyticsStoreService.setBuyingCoinPackage(eventData);
  }

  public ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}